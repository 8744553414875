






















































































import { Component } from 'vue-property-decorator';
import {
  dispatchCreatePanel, dispatchExpireDataByModuleName, dispatchGetBiomarkersForList,
} from '@/store/crud/actions';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { ICreatePanel, IPanel } from '@/interfaces/panels';
import { crudModules } from '@/constants/globalConstants';



@Component({})
export default class CreatePanel extends AppComponent {
  public valid = false;
  /*
    Panel data
  */
  public name: string = '';
  public questTestCode: string = '';
  public modeOfAquisition: string = '';
  public questPrice: string = '';
  public description: string = '';

  private selectedBiomarkers: string[] = []; // biomarkers selected by user


  /**
   * on create hook
   * it just call async init view to fetch data and configure the view
   */
  public created() {
    this.initView();
  }

  public async initView() {
    this.setAppLoading(true);
    await this.fetchData();
    this.reset();
    this.setAppLoading(false);
  }

  /**
   * fetches data from the store / api asynchronously
   */
  public async fetchData() {
    await dispatchGetBiomarkersForList(this.$store);
  }

  /**
   * reset the form
   */
  public reset() {
    this.name = '';
    this.questTestCode = '';
    this.modeOfAquisition = '';
    this.questPrice = '';
    this.$validator.reset();
  }

  /**
   * it cancels the creation and returns back to the Admin Panel
   */
  public cancel() {
    this.$router.back();
  }

  /**
   * It maps the form data to the IPanel interface
   * and submits it to the server
   */
  public async submit() {
    if (await this.$validator.validateAll()) {
      const protoPanel: IPanel = {} as IPanel;

      if (!this.hasValue(this.name)) {
        this.toast('Name is required', true);
        return;
      }

      if (this.hasValue(this.name)) { protoPanel.name = this.name; }
      if (this.hasValue(this.questTestCode)) { protoPanel.questTestCode = this.questTestCode; }
      if (this.hasValue(this.modeOfAquisition)) { protoPanel.modeOfAcquisition = this.modeOfAquisition; }
      if (this.hasValue(this.questPrice)) {
        /*
          Following check is probably redundant, since the validation
          should prevent the user from entering invalid data
          but it's here just in case an smart user bypasses the validation
        */
        if (!this.isValidFloat(this.questPrice)) {
            this.toast('Quest price is must be a valid number', true);
            return;
          }
        protoPanel.questPrice = this.questPrice;
      }

      if (this.hasValue(this.description)) { protoPanel.description = this.description; }
      if (this.selectedBiomarkers && this.selectedBiomarkers.length > 0) {
        protoPanel.biomarkers = this.selectedBiomarkers as string[];
      }

      const updatedPanel: ICreatePanel = protoPanel as ICreatePanel;
      const result = await dispatchCreatePanel(this.$store, updatedPanel);
      if (result) {
        this.$router.push('/main/content/panels');
        // expire the packages data to force a fetch from the server
        dispatchExpireDataByModuleName(this.$store, crudModules.PACKAGES);
      }
    }
  }
}
